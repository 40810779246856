
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,h2,h3,h4,h5,h6,p {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: 'Gotham';
  src: url('./assets/fonts/Gotham-Bold.otf') format('otf'),
       url('./assets/fonts/Gotham-Book.woff') format('woff'),
       url('./assets/fonts/Gotham-Book.woff2') format('woff2'),
       url('./assets/fonts/Gotham-Book.ttf') format('truetype');
   font-weight: 600;
   font-style: normal;
 }

 .sticky {
  position: fixed;
  top: 0;
  z-index: 100;
  background: white;
  animation: smoothScroll 2s forwards;
}

@keyframes smoothScroll {
	0% {
		transform: translateY(-90px);
	}
	100% {
		transform: translateY(0px);
	}
}


.active_lnav_background {
  position: fixed;
  top: 90px;
  left: 0;
  min-width: 100vw;
  min-height: 100vh;
  z-index: 2000;
  background: rgba(0,0,0, 0.16);
}


::-webkit-scrollbar {
  width: 10px;
  background: #EDEDED;
  border: none;
  cursor: pointer;
}
  
::-webkit-scrollbar-track-piece {
  background-color: #F5F8F9;
}
  
::-webkit-scrollbar-thumb {
  background: lightgray;
  border: 3px solid white;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--color-primary);
}
